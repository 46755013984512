import gql from 'graphql-tag';
import { FORM_QUERY_BLOCK } from "./constants/form-query-block";
import { SWITCH_QUERY_BLOCK } from './constants/switch-query-block';

const PageQuery = gql`
  query ContentPage(
    $market: String!
    $lang: String!
    $path: String
    $includestubs: Boolean
    $paymentVehicle: String
    $paymentYear: [Int]
    $transmissionCode: String
    $paymentModel: String
    $downPayment: Float
    $tradeInValue: Float
    $financeTerm: Int
    $leaseTerm: Int
    $zipCode: String
    $rate: Float
    $url: String
    $annualMileage: String
    $component: String
    $datasetName: String
  ) {
    ContentPage(
      market: $market
      lang: $lang
      path: $path
      includestubs: $includestubs
      paymentVehicle: $paymentVehicle
      paymentYear: $paymentYear
      paymentModel: $paymentModel
      transmissionCode: $transmissionCode
      downPayment: $downPayment
      tradeInValue: $tradeInValue
      financeTerm: $financeTerm
      leaseTerm: $leaseTerm
      zipCode: $zipCode
      rate: $rate
      url: $url
      annualMileage: $annualMileage
      component: $component
      datasetName: $datasetName
    ) {
      id
      pageTitle
      pageDescription
      googleVerificationKey
      template
      canonicalTag
      twitterCardType
      twitterAccount
      twitterTitle
      twitterDescription
      twitterCardImage
      noindex
      nofollow
      facebookInsightsAppID
      overrideParentPageLabelText
      enableSubNavVLP
      childPageTitle
      childPageDescription
      useRouteParams
      merchantInfo
      feefoSnippetKey
      hideBreadcrumb
      alterNativePageList {
        redirectVanityURL
        hideInNav
        locale
        path
        domain
      }
      searchRootPath
      searchNavigationPath
      jsonLD {
        jsonLDData
      }
      openGraphImage {
        image
        altText
      }
      seoKeywords {
        label
      }
      enableLocationPrompt
      locationPrompt {
        title
        description
        inputLocationLabel
        useCurrentLocationLabel
        locationSelectedMessage
        locationIgnoredMessage
        ctaLabel
      }
      components {
        ... on ChargingStation {
          resourceType
          freeChargingKeyLabel
          mobileListTabLabel
          chargeTypeLabel
          resultSummary
          networkLabel
          searchFieldPlaceholder
          getMyLocationLabel
          mobileMapTabLabel
          paidChargingKeyLabel
          paidChargingKeyColour
          filterHeader
          costLabel
          titleText
          subTitleText
          description
          getDirectionLabel
          freeChargingKeyColour
          radius
          radiusDistanceFromZipCodeType
          rateFilter
          plugTypeFilters
          chargeTypesFilters {
            description
            label
          }
          networksFilters {
            description
            label
          }
          costsFilters {
            description
            label
          }
          disclaimerData {
            id
            detail
          }
        }
        ... on PhevSavingsCalculator {
          resourceType
          disclaimerData {
            id
            detail
          }
          headlineEyebrow
          introText
          headlines
          moreDetails
          lessDetails
          dailyDistancePrefix
          evModeLabel
          usingLabel
          drivingCityLabel
          drivingHighwaysLabel
          drivingBothLabel
          chargeTypeLabel
          chargeTypeDefaultValue
          gallonsLabel
          gallonsAnnualCostLabel
          savingResultIntroText
          emissionsSavingsPrefix
          emissionsSavingsSuffix
          incentiveEyebrow
          incentiveHeadlines
          incentiveIntroText
          searchLabel
          searchCtaLabel
          searchCurrentLocationLabel
          searchErrorMessage
          resultHeadlinePrefix
          resultHeadlineSuffix
          resultHeadlinePrefixSingleIncentive
          resultHeadlineSuffixSingleIncentive
          disableIncentiveFinder
          resultIntroText
          filterAllIncentivesLabel
          filterCashIncentivesLabel
          totalSavingLabel
          disclaimerLabel
          disclaimerCollapseStatus
          disclaimerList {
            disclaimerNumber
            disclaimerContent
            associated
          }
          contactDealerCTA {
            label
            URL
            priority
            openNewTab
            theme
            ctaType
          }
          milesLabel
        }
        ... on Search {
          resourceType
          label
          placeholder
          viewMoreLabel
          viewmorepagenation
          emptyResultsMessage
          searchInputWarningMessage
        }
        ... on SpacerBlock {
          mobileheight
          desktopheight
          tabletheight
          resourceType
        }
        ... on NewsCards {
          id
          resourceType
          title
          cardVariant
          backgroundColor
          enableMobileCarousel
          removeTopMargin
          removeBottomMargin
          cta {
            label
            url
            openNewTab
          }
          cardItems {
            colorStyle
            imageAlignment
            hideArticleDescription
            articleInformation {
              id
              date
              sectionHeading
              paragraph
              image {
                image
                altText
              }
              link {
                url
              }
              categoryName
              readTime
            }
          }
        }
        ... on Specifications {
          resourceType
          title
          label
          vehicle {
            name
            vehicleImage {
              image
              altText
            }
          }
          hideComparationTabs
          dimensionImages {
            image
            altText
          }
          showImageWithDimentions
          showFeaturesWithAlignment
          buttonAlignment
          hasModelsOrdering
          models {
            id
            code
            name
            sortOrder
            price {
              value
              label
              displayValue
              zeroMessage
            }
            specifications {
              category
              label
              value
              unitOfMeasurement
              sortOrder
            }
            features {
              parentCategory
              category
              description
              sortOrder
              isKeyFeature
            }
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
        }

        ... on FeefoReviews {
          resourceType
          productSkuId
          id
        }
        
        ... on FeefoScoreBadge {
          resourceType
					vehicleSkuId
          eyebrowText
          introText
          vehicleSelection
          headerText
          theme
          url
          disclaimerData {
            id
            detail
          }
        }

        ... on FeefoHighlightedSnippets {
          resourceType
					vehicleSkuId
        }

        ... on CookieList {
          resourceType
        }
          
        ... on PaymentCalculator {
          resourceType
          msrpDisclaimerId
          financeMonthlyPaymentDisclaimerId
          interestRateDisclaimerId
          leaseMonthlyPaymentDisclaimerId
          dueAtLeaseSigningDisclaimerId
          annualMileageDisclaimerId
          paymentCalculatorLabel
          vehicleName
          msrpLabel
          msrpDisclaimerNo
          ctaText
          ctaUrl
          hasModelsOrdering
          vehicles {
            modelName
            imagePath
            imageAltText
            modelCode
            msrpValue
            msrpValueDisplay
            transmissionCode
            modelYear
            keyFeatures
            sortOrder
          }
          buyTabLabel
          buyTabUnavailableLabel
          leaseTabLabel
          leaseTabUnavailableLabel
          downPaymentLabel
          tradeInValueLabel
          termsLabel
          interestLabel
          monthlyLabel
          perMonthLabel
          customRateLabel
          defaultRateLabel
          amountFinancedLabel
          amountFinancedInclusionText
          requestQuoteLabel
          shareCalculationLabel
          seeAllOffersLabel
          annualMileageLabel
          dueLeasingLabel
          dueLeasingInclusionText
          calculationErrorTitle
          calculationErrorText
          viewHomePageLabel
          viewHomePagePath
          regexRestrictDecimalPricing
          regexRestrictDecimalRating
          sharePaymentPlanHeadingText
          shareCode
          collapseStatus
          disclaimerLabel
          disclaimerList {
            disclaimerNumber
            disclaimerContent
            associated
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
          minimumLoanAllowance
          minimumLoanAllowanceError
          labelOffer
          errorMessageDownPaymentAndTradeIn
          financeCalculation {
            downPayment {
              value
              displayValue
            }
            tradeInValue {
              value
              displayValue
            }
            terms {
              value
              default
            }
            rate
            validationRateErrorMsg
            customerCashback {
              value
              displayValue
            }
            amountFinanced {
              value
              displayValue
            }
            monthlyAmount {
              value
              displayValue
            }
            monthlyDisclaimerNo
            viewSpecialOffersLink
            requestQuoteRedirectUrl
            interestRateDisclaimerNo
          }
          leaseCalculation {
            downPayment {
              value
              displayValue
            }
            tradeInValue {
              value
              displayValue
            }
            terms {
              value
              default
            }
            annualMileage
            annualMileageDisclaimerNo
            dueAtLeasing {
              value
              displayValue
            }
            leaseSigningDisclaimerNo
            monthlyAmount {
              value
              displayValue
            }
            monthlyDisclaimerNo
            viewSpecialOffersLink
            requestQuoteRedirectUrl
          }
          cashCalculation {
            cashOfferLabel
            includeDiscountLabel
            includeDiscount {
              value
              displayValue
            }
            viewSpecialOffersLink
          }
          allOffersRedirectUrl
          costBreakDownBtnLabel
          costBreakDownPopupTitle
          costLabel
          taxesLabel
          summaryLabel
          subTotalLabel
          subSellingPrice
          returnToCalculatorLabel
          taxeFeeFilterPopupTitle
          taxeFeesHeading
          rebatesTitle
          applyAndCloseBtn
          disclaimerMessage
          effectiveInterestRateLabel #new fields below
          weeklyPaymentLabel
          monthlyPaymentLabel
          firstPaymentLabel
          totalDueOnDeliveryLabel
          costOfBorrowingLabel
          interestPayableLabel
          includesSalesTaxLabel
          includeFeesLabel
          cashTabLabel
          itemColumnLabel
          costColumnLabel
          taxesFeesAndRebatesLabel
          copy
          paymentFrequencyLabel
          paymentFrequencyOptions
          zipCodeTitle,
          zipCodePlaceHolder,
          zipCodeForm,
          buildAndPriceLabel
          priceConfigLabel
          dueCashLabel
          dueCashInclusionText
          dueFinanceLabel
          dueFinanceInclusionText
          dueAtCashDisclaimerId
          cashPaymentDisclaimerId
          optionsLabel
          costSummarySectionTitle
          totalPurchaseLabel
          totalSellingPriceSectionTitle
          taxAndRebatesSectionTitle
          totalPaymentSectionTitle
          totalPayableSectionTitle
          totalDueOnDeliverySectionTitle
          costOfBorrowingSectionTitle
          rebatesLabel
          customerRebateLabel
          totalTaxAndRebatesLabel
          paymentLabel
          breakdownTradeInLabel
          breakdownDownPaymentLabel
          totalPayableLabel
          endValueLabel
          ppsaARegistration
          salesTaxDueOnDeliveryLabel
          leasePaymentFrequency {
            label
            value
          }
          financePaymentFrequency {
            label
            value
          }
        }

        # ... on PaymentRequestAQuoteForm {
        #   trim
        #   paymentType
        #   headingText
        #   subHeadingText
        #   monthlyPayment
        #   monthlyPaymentLabel
        #   term
        #   paymentTermLabel
        #   imagePath
        #   imageAltText
        #   financedAmount
        #   financeLabel
        #   finaceDescription
        #   leaseSigning
        #   leaseSigningDisclaimerNo
        #   leaseLabel
        #   leaseDescription
        #   inventoryLabel
        #   ctaAreaBottomTitle
        #   calculationErrorTitle
        #   calculationErrorText
        #   viewHomePageLabel
        #   viewHomePagePath
        #   addPriceDisclaimer
        #   priceDisclaimerId
        #   disclaimerData {
        #     id
        #     detail
        #   }
        # }

        ... on ModelCalculation {
          disclaimerData {
            id
            detail
          }
          addPriceDisclaimer
          priceDisclaimerId
          resourceType
          subHeadingText
          paymentHeadingLabel
          paymentTermLabel
          buyCalculationLabel
          buyDescriptionLabel
          leaseCalculationLabel
          leaseDescriptionLabel
          inventoryLabel
          collapseStatus
          disclaimerLabel
          disclaimerList {
            disclaimerNumber
            disclaimerContent
            associated
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
        }
        ... on MultiVehiclesSelector {
          resourceType
          locale
          zipCodeEnabled
          zipCodeHeadingText
          zipCodeLabel
          zipCodeErrorMsg
          zipCode {
            validationRegEx
          }
          msrpPrefix
          msrpDisclaimerNo
          modelYearLabel
          modelYear {
            year
            id
          }
          modelNotAvailableMsg
          vehicleCheckboxEnabled
          vehicles {
            vehicleName
            vehicleCode
            vehicleYear
            imagePath
            imageAltText
            MSRP
            isActive
            vehicleNextGenConfigurator
            configuratorURL
            price {
              value
              displayValue
              zeroMessage
            }
          }
          ctaEnabled
          ctaLabel
          ctaRedirectLink
          paymentCalculatorRedirectLink
          collapseStatus
          disclaimerLabel
          disclaimerList {
            disclaimerNumber
            disclaimerContent
            associated
          }
          customizedLabel
          ogcConfiguratorPath
          ngcConfgiuratorPath
          configuratorCodeEntryHeadingText
          configuratorCodeEntryTooltip
          configuratorRetrieveHeadingText
          configuratorCodeEntryLabel
          configuratorCodeEntryPlaceholder
          configuratorCodeEntryError
          configuratorCtas
          configuratorCodeEntryContinue
          configuratorCodeEntryStartNew
          configuratorErrorMessageHeading
          configuratorErrorMessage
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
        }
        ... on MiniTimeline {
          resourceType
          headerText
          introText
          miniTimelineBlocks {
            headingText
            subHeadline
            description
            disclaimerData {
              id
              detail
            }
          }
          disclaimerData {
            id
            detail
          }
        }
        ... on ChatbotPanel {
          titleText
          content
          liveChatArea
          liveChatLocale
          subTitleText
          resourceType
          lastModified
        }
        ... on FinanceData {
          resourceType
          viewMoreLabel
          viewLessLabel
          financeColumns {
            textSectionBlock {
              sectionHeading
              mainHeading
              paragraph
              legal
            }
            link {
              url
              label
              priority
              ctaType
              icon
            }
            infographic {
              image {
                image
                altText
              }
              leftLabel
              centerLabel
              rightLabel
              description
            }
          }
          moreInfo {
            description
            columns {
              textSectionBlock {
                sectionHeading
                mainHeading
                paragraph
                legal
              }
              textAlignment
              link {
                label
                url
              }
              list
              infographic {
                image {
                  image
                  altText
                }
                leftLabel
                centerLabel
                rightLabel
                description
              }
            }
          }
        }

        ... on FinanceCalculator {
          resourceType
          jsonData
        }

        ... on HTMLEditor {
          resourceType
          html
        }

        ... on ProfileGrid {
          resourceType
          contactCards {
            image {
              image
              altText
            }
            userInformation {
              sectionHeading
              mainHeading
              paragraph
            }
            contactCTAs {
              icon
              label
            }
          }
        }

        ... on Video {
          resourceType
          youtubeVideoId
          offset
        }

        ... on MiniConfigurator {
          id
          resourceType
          animatedImage
          cta {
            label
            priority
          }
          componentLabel
          titleAndText {
            title
            text
          }
          defaultExtColor
          productSelector {
            titleAndText {
              title
              text
            }
            inputLabel
            products {
              id
              name
              code
              year
              codeYear
              cta {
                url
              }
              specifications {
                id
                label
                type
                hexCode
                secondaryHexCode
                code
                titleAndText {
                  title
                  text
                }
                images {
                  image
                }
                image {
                  image
                }
                animationImages {
                  tire {
                    image
                  }
                  body {
                    image
                  }
                  shadow {
                    image
                  }
                }
              }
            }
          }
        }

        ... on DealerLocator {
          id
          resourceType
          lastModified
          title
          subHeadline
          searchHelpText
          locationCtaLabel
          serviceWebsiteCTALabel
          serviceSearchFilterLabel
          salesSearchFilterLabel
          allDealershipsSearchFilterLabel
          getDirectionsLabel
          headingTag
          testDriveCTA {
            URL
            label
            openNewTab
          }
          carServiceCTA {
            URL
            label
            openNewTab
          }
          clickShopCTA {
            URL
            label
            openNewTab
          }
          scheduleServiceLabel
          clickshopParamters
          contactUsLabel
          cta {
            url
            label
            priority
            icon
            openNewTab
          }
          searchInventoryLabel
          searchRadius
          showRating
          searchResultsFoundLabel
          saleOpeningHoursLabel
          serviceOpeningHoursLabel
          contactLabel
          metricSystem
          viewMoreText
          noGeolocationMessage
          noDealershipFoundMessage
          salesLabel
          servicesLabel
          bodyAndPaintLabel
          usedCarsSalesLabel
          listLabel
          mapLabel
          additionalFilters {
            label
            value
          }
          zoomLevel
        }

        ... on IFrame {
          id
          resourceType
          lastModified
          src
          title
          allowGeolocation
          addGTM
          iframeHeight
          iframeMobileHeight
          iframeMobileWidth
          iframeTabletHeight
          iframeTabletWidth
          iframeDesktopHeight
          iframeDesktopWidth
          iframeSize
          position
          theme
          defaultSetting
          enableJato
          mobileIframePath
          jatoDisclaimerText
          jatoLogoImage {
            image
            altText
          }
        }

        ... on TableStep {
          resourceType
          categories {
            heading
            order
            textSection {
              lastModified
              paragraph
              link {
                url
                label
                priority
                openNewTab
                icon
              }
              anchorPoint
            }
          }
          disclaimerData {
            id
            detail
          }
        }

        ... on BuzzPanel {
          id
          resourceType
          lastModified
          componentLabel
          sectionTitle
          sectionId
          buzzTiles {
            date
            contentType {
              label
            }
            heading
            tileLayout
            link {
              url
              openNewTab
            }
            image {
              image
            }
          }
          buzzLayout
        }

        ... on FilterSection {
          id
          resourceType
          lastModified
          filters {
            label
            filteringOptions {
              label
              image {
                altText
                image
              }
              imageSelected {
                altText
                image
              }
              price {
                label
                value
                displayValue
                zeroMessage
              }
              type
              id
              titleAndText {
                title
                text
              }
              selected
            }
          }
        }

        ... on FullWidthPanel {
          id
          resourceType
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
          lastModified
          headingTag
          heading1
          heading2
          heading3
          price {
            value
            displayValue
            zeroMessage
          }
          textBlock
          textBlockPosition
          sectionTitle
          sectionId
          vehicleLogo {
            image
          }
          tag {
            code
            label
          }
          featureBlock {
            title
            image {
              image
              altText
            }
            description
            icon
          }
          cta {
            label
            URL
            priority
            openNewTab
            theme
            icon
            ctaType
          }
          link {
            label
            URL
            priority
            openNewTab
            theme
            icon
            ctaType
          }
          backgroundImage {
            image
            altText
          }
          image {
            image
            altText
          }
          video {
            source
            autoplay
            muted
            loop
            poster {
              image
              altText
            }
            youtubeVideoId
          }
          mobileVideo {
            source
            autoplay
            muted
            loop
            poster {
              image
              altText
            }
          }
          hideVideoOnMobile
          mobileImage {
            image
            altText
          }
          size
          aspectRatio
          carAlignment
          theme
          navigationLinks {
            url
            label
            openNewTab
          }
        }

        ... on KeyBuyingAction {
          id
          resourceType
          lastModified
          hideActionBar
          links {
            openNewTab
            url
            label
            icon
            image {
              image
            }
          }
          image {
            image
          }
        }

        ... on BrandPanel {
          disclaimerData {
            id
            detail
          }
          id
          resourceType
          lastModified
          sectionTitle
          sectionId
          panel {
            image {
              image
              altText
            }
            heading1
            heading2
            headingLabel
            textBlock
            link {
              URL
              label
              ctaType
              theme
              priority
              icon
              openNewTab
            }
          }
        }

        ... on Offer {
          locale
          resourceType
          offerLabel {
            viewOfferCTA
            viewHomePage
            seeAllVehicleOffers
            viewHomePagePath
            filterOfferHeadingText
            seeOffersLabel
            seeOffersForVehiclesLabel
            seeOffersNearZipCodeLabel
            seeOffersForVehiclesNearZipCodeLabel
          }
          offerFilter {
            offerFilterSelectionMobileLabel
            allOfferTypeSelectionLabel
            allModelYearLabel
            offerTypeFilterLabel
            allVehiclesLabel
            selectVehiclesLabel
            offerNotAvailable
            filterOfferHeadingText
            zipCodeErrorMsg
            offerNotAvailableTitle
            clearButtonLabel
            errorTitle
            errorContent
            timeOutMsgTitle
            timeOutMsgContent
            zipCodeLabel
            modelYearLabel
            applyButtonLabel
            cancelButtonLabel
            zipCode {
              validationRegEx
            }
            vehicles {
              vehicleName
              imagePath
              code
              noOfOffers
              imageAltText
            }
            offerTypes {
              label
              id
            }
            modelYear {
              year
              id
            }
            offersLabel
          }
          offerList {
            vehicleName
            vehicleCode
            image
            parentProductSkuId
            isEnableProductStar
            altText
            legalTextForImageVehicle
            legalTextForImageDisclaimerData {
              id
              detail
            }
            offers {
              offerCode
              modelName
              trimCode
              trimName
              offerCode
              offerType
              offerTypeDisplayName
              offerDisclaimerLabel
              detailPageUrl
              cardEyebrowText
              legalTextImage
              subHeadlineTextTwo
              descriptions {
                type
                text
              }
              disclaimerData {
                id
                detail
              }
            }
          }
        }

        ... on OfferDetail {
          resourceType
          addPriceDisclaimer
          allInPriceLabel
          priceDisclaimerId
          offerSubHeadlineText2
          disclaimerLabel
          offerDetail {
            allInPriceValue
            legalTextImage
            modelCode
            modelName
            modelYear
            trimCode
            trimName
            transmissionCode
            vehicleCode
            vehicleName
            displayName
            offerCode
            offerType
            offerValid
            offerDisclaimerLabel
            imagePath
            imageAltText
            shareOfferCode
            descriptions {
              type
              text
            }
            CTAs {
              label
              url
              priority
              theme
              ctaType
              icon
              openNewTab
            }      
            disclaimerData {
              id
              detail
            }
          }

          inventoryLabel
          inventoryDisplay
          inventoryRedirectionURL

          buildAndPriceLabel
          buildAndPriceDisplay
          buildAndPriceRedirectionUrl

          shareOrPrintOfferLabel

          tradeInAppraisalOfferLabel
          enableViewTradeInAppraisal
          tradeInAppraisalOfferUrl

          shareOfferLabel
          shareOfferDisplay

          printOfferLabel
          printOfferDisplay

          shareOfferDialogLabel
          copiedLabel
          copyLabel
          shareIconText

          availabilityMsg

          clickShopEnabled
          raqCta {
            label
            link
          }

          backButtonLabel
        }

        ... on OfferModule {
          resourceType
          offerModule {
            modelCode
            modelName
            trimCode
            trimName
            transmissionCode
            vehicleCode
            vehicleName
            offerCode
            displayName
            offerType
            modelYear
            redirectUrl
            descriptions {
              type
              text
            }
          }
          viewDetailsLabel
          seeAllOffersLabel
          seeAllOffersRedirect
        }

        ... on ProductLineUp {
          id
          resourceType
          lastModified
          buildText
          vehicles {
            id
            code
            price {
              label
              value
              zeroMessage
            }
            vehicleImage {
              altText
              image
            }
            vehicleBackgroundImage {
              altText
              image
            }
            name
            description
          }
        }

        ... on SelectionSummary {
          id
          resourceType
          lastModified
          summaryText
          changeText
          includedText
          editText
          summaryTitle
          configurationTitle
          customisationTitle
          copyText
          hideLink
          nextButton
          summaryImage
          includedAccessoriesTitle
          vehicle {
            id
            code
            price {
              label
              value
              displayValue
              zeroMessage
            }
            name
            selected
          }
          model {
            id
            code
            price {
              label
              value
              displayValue
              zeroMessage
            }
            name
            selected
          }
          color {
            id
            type
            code
            label
            price {
              label
              value
              displayValue
            }
            titleAndText {
              title
              text
            }
            selected
          }
          fabric {
            id
            type
            code
            label
            price {
              label
              value
              displayValue
            }
            titleAndText {
              title
              text
            }
            selected
          }
          packages {
            id
            type
            code
            label
            price {
              label
              value
              displayValue
            }
            titleAndText {
              title
              text
            }
            selected
          }
          accessories {
            type
            code
            label
            colorsLabel
            colorDescription
            dimensions
            dimensionsLabel
            compatibleName
            compatibleLabel
            price {
              label
              value
              displayValue
            }
            titleAndText {
              title
              text
            }
            selected
          }
          totalPrice {
            label
            value
            displayValue
          }
          fees {
            label
            value
          }
          preTaxPrice {
            value
            label
            displayValue
          }
          driveType {
            id
            type
            code
            label
            image {
              altText
              image
            }
            selected
          }
          fuelType {
            id
            type
            code
            label
            selected
          }
          transmissionType {
            id
            type
            code
            label
            image {
              altText
              image
            }
            selected
          }
        }

        ... on Carousel {
          id
          resourceType
          lastModified
          headingTag
          introText
          titleAndText {
            title
            text
          }
          staticImage {
            altText
            image
          }
          items {
            titleAndText {
              title
              text
            }
            image {
              altText
              image
            }
            backgroundImage {
              altText
              image
            }
            content {
              text
              title
            }
            valueSection {
              label
              value
              valueSize
              icon
            }
            cta {
              label
              url
              priority
              openNewTab
              icon
            }
          }
          disclaimerData {
            id
            detail
          }
        }

        ... on ModelSelector {
          id
          resourceType
          lastModified
          label
          compareLink {
            URL
            label
            openNewTab
          }
          titleAndText {
            title
            text
          }
          models {
            id
            code
            price {
              label
              value
              displayValue
              zeroMessage
            }
            name
            description
            selected
            exteriorColors {
              id
              code
              name
              image {
                image
                altText
              }
              price {
                label
                value
                displayValue
                baseValue
                fees {
                  label
                  value
                  id
                }
                zeroMessage
              }
            }
            specifications {
              id
              label
              titleAndText {
                title
                text
              }
            }
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
        }

        ... on PackagesSelector {
          id
          resourceType
          lastModified
          label
          filter {
            code
            label
            filteringOptions {
              id
              type
              code
              label
              image {
                altText
                image
              }
              price {
                label
                value
                displayValue
              }
              includedOptions {
                id
                type
                label
                titleAndText {
                  title
                  text
                }
                image {
                  image
                  altText
                }
                link {
                  URL
                  label
                  openNewTab
                }
              }
              titleAndText {
                title
                text
              }
              selected
            }
          }
          titleAndText {
            title
            text
          }
        }

        ... on Editorial {
          id
          resourceType
          lastModified
          editorialLayout
          disclaimerData {
            id
            detail
          }
          columns {
            addPriceDisclaimer
            priceDisclaimerId
            image {
              image
              altText
            }
            video {
              source
              autoplay
              muted
              loop
              poster {
                image
                altText
              }
              youtubeVideoId
            }
            hideVideoOnMobile
            price {
              value
              displayValue
              zeroMessage
            }
            tag {
              label
            }
            overlayImage {
              image
            }
            specifications {
              image {
                image
                altText
              }
              label
            }
            description {
              textAlignment
              sectionHeading
              mainHeading
              paragraph
              legal
            }
            selectButton
            link {
              url
              label
              priority
              openNewTab
              ctaType
              icon
            }
            selectStyle
            downloadPDFText
            downloadPDFIcon
            displayVehiclePrice
            link2 {
              url
              label
              priority
              openNewTab
            }
            link3 {
              url
              label
              priority
              openNewTab
            }
            icons {
              icon
              label
            }
          }
        }

        ... on CarSelector {
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
          id
          resourceType
          lastModified
          selectedLabel
          componentLabel
          selectIcon {
            altText
            image
          }
          showSelection
          enableTwoColumns
          productTile {
            enableProductsRatingStars
            productSkuId
            parentProductSkuId
            code
            codeYear
            year
            price {
              value
              displayValue
              zeroMessage
            }
            vehicleImage {
              image
              altText
            }
            vehicleBackgroundImage {
              image
              altText
            }
            name
            cta {
              url
              label
            }
            buildLink {
              url
              label
            }
            tag {
              code
              label
            }
            vehicleNextGenConfigurator
          }
          customizedLabel
        }

        ... on SwatchSelector {
          id
          resourceType
          lastModified
          label
          titleAndText {
            title
            text
          }
          filter {
            code
            label
            filteringOptions {
              id
              type
              code
              label
              image {
                altText
                image
              }
              price {
                label
                value
                displayValue
              }
              titleAndText {
                title
                text
              }
              selected
            }
          }
        }

        ... on ProductFeaturePanel {
          id
          resourceType
          lastModified
          sectionTitle
          sectionId
          textSectionBlock {
            headingTag
            sectionHeading
            mainHeading
            paragraph
          }
          link {
            ctaType
            priority
            url
            label
            icon
          }
          link2 {
            ctaType
            priority
            url
            label
            icon
          }
          link3 {
            ctaType
            priority
            url
            label
            icon
          }
          price {
            label
            value
            displayValue
            zeroMessage
          }
          features {
            icon
            label
          }
          backgroundImage {
            image
            altText
          }
          mobileImage {
            image
            altText
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
        }

        ... on TextSectionBlock {
          id
          disclaimerData {
            id
            detail
          }
          addPriceDisclaimer
          priceDisclaimerId
          resourceType
          lastModified
          sectionTitle
          sectionId
          headingTag
          width
          image {
            altText
            image
          }
          link {
            url
            label
            priority
            openNewTab
            theme
            icon
          }
          mainHeading
          textAlignment
          sectionHeading
          disclaimerLabel
          useAccordion
          hideDisclaimer
          paragraph
          theme
          logo {
            altText
            image
          }
          offerTag {
            code
            label
          }
        }

        ... on CtaRow {
          id
          resourceType
          lastModified
          headline
          alignment
          description
          label
          textSpan
          CTAs {
            label
            url
            priority
            theme
            ctaType
            icon
            openNewTab
          }
        }

        ... on CtaRowV2 {
          resourceType
          headline
          ctas {
            label
            link
            linkOriginal
            selectActionType
            openNewTab
          }
        }

        ... on TabbedContainer {
          id
          resourceType
          lastModified
          label
          disclaimerData {
            id
            detail
          }
          headingDetails {
            legal
            sectionHeading
            mainHeading
            paragraph
          }
          tabs {
            type
            label
            disclaimerData {
              id
              detail
            }
            components {
              ... on Editorial {
                resourceType
                lastModified
                editorialLayout
                disclaimerData {
                  id
                  detail
                }
                columns {
                  image {
                    image
                  }
                  price {
                    displayValue
                    zeroMessage
                  }
                  tag {
                    label
                  }
                  overlayImage {
                    image
                  }
                  specifications {
                    image {
                      image
                    }
                    label
                  }
                  description {
                    textAlignment
                    sectionHeading
                    mainHeading
                    paragraph
                    legal
                  }
                  selectButton
                  link {
                    url
                    label
                    priority
                    openNewTab
                  }
                  selectStyle
                  downloadPDFText
                  downloadPDFIcon
                  displayVehiclePrice
                }
              }
              ... on Accordion {
                heading
                question
                answer
                video
                additionalDescription
                link {
                  url
                  label
                  priority
                  icon
                  openNewTab
                }
              }
              ${SWITCH_QUERY_BLOCK}  
              ... on ${FORM_QUERY_BLOCK}
            }
          }
        }

        ... on Contact {
          id
          resourceType
          lastModified
          columns {
            heading
            contacts {
              heading
              contact {
                url
                label
                priority
                icon
              }
            }
          }
        }

        ... on Hero {
          disclaimerData {
            id
            detail
          }
          id
          resourceType
          lastModified
          headingLarge
          sectionTitle
          sectionId
          backgroundImage {
            image
            altText
          }
          video {
            source
            autoplay
            muted
            loop
            poster {
              image
              altText
            }
          }
          hideVideoOnMobile
          navigationLinks {
            url
            label
            openNewTab
          }
        }

        ... on HeroCarousel {
          id
          resourceType
          lastModified
          theme
          autoplay
          autoplaySpeed
          cyclical
          slides {
            id
            resourceType
            lastModified
            disclaimerData {
              id
              detail
            }
            heading1
            heading2
            heading3
            price {
              value
              displayValue
              zeroMessage
            }
            textBlock
            textBlockPosition
            sectionTitle
            sectionId
            vehicleLogo {
              image
            }
            tag {
              code
              label
            }
            featureBlock {
              title
              image {
                image
                altText
              }
              description
              icon
            }
            cta {
              label
              URL
              priority
              openNewTab
              theme
              icon
              ctaType
            }
            link {
              label
              URL
              priority
              openNewTab
              theme
              icon
              ctaType
            }
            backgroundImage {
              image
              altText
            }
            image {
              image
              altText
            }
            video {
              source
              autoplay
              muted
              loop
              poster {
                image
                altText
              }
              youtubeVideoId
            }
            mobileVideo {
              source
              autoplay
              muted
              loop
              poster {
                image
                altText
              }
            }
            hideVideoOnMobile
            mobileImage {
              image
              altText
            }
            size
            aspectRatio
            carAlignment
            theme
            navigationLinks {
              url
              label
              openNewTab
            }
          }
        }

        ... on TabGallery {
          resourceType
          eyebrow
          header
          introText
          disclaimerData {
            id
            detail
          }
          items {
            youtubeLink
            internalVideo
            image {
              image
              altText
            }
            description
            label
            cta {
              url
              label
              priority
              icon
              openNewTab
            }
          }
        }

        ... on FaqCardsPanel {
          resourceType
          introHeader
          introText
          answerLabel
          backLabel
          cards {
            question
            answer
            layout
            image {
              image
              altText
            }
          }
          CTAs {
            label
            priority
            url
            theme
            ctaType
            icon
            openNewTab
          }
          disclaimerData {
            id
            detail
          }
        }

        ... on TextQuote {
          id
          resourceType
          lastModified
          quoteText
          quoteTextSmall
          caption
          alignment
          link {
            url
            label
          }
          image {
            image
            altText
          }
          theme
        }

        ... on XLQuoteCarousel {
          id
          disclaimerData {
            id
            detail
          }
          resourceType
          sectionHeading
          theme
          lastModified
          cyclical
          autoplay
          autoplaySpeed
          slides {
            id
            resourceType
            lastModified
            quoteText
            quoteTextSmall
            caption
            alignment
            link {
              url
              label
              priority
              icon
              openNewTab
            }
            image {
              image
              altText
            }
            theme
            disclaimerData {
              id
              detail
            }
          }
        }

        ... on LocationSelector {
          resourceType
          title
          description
          useCurrentLocationLabel
          inputLocationLabel
        }

        ... on LocatorMap {
          id
          resourceType
          lastModified
          image {
            image
            altText
          }
          hoursTitle
          contactTitle
          contactEmailTitle
          contactChatTitle
          contactPhone1
          contactPhone2
          location {
            name
            address {
              country
              city
              postalArea
              addressLine1
              addressLine2
              addressLine3
            }
            dealerDepartments {
              workingHours {
                dayOfTheWeek
                openTime
                closeTime
                status
              }
            }
          }
        }

        ... on ImagePanel {
          id
          resourceType
          lastModified
          offset
          sectionTitle
          sectionId
          noCropMobile
          image {
            image
            altText
          }
          video {
            source
            autoplay
            muted
            loop
            poster {
              image
              altText
            }
            youtubeVideoId
          }
          hideVideoOnMobile
          mobileFocusArea
        }

        ... on VehicleConfigurator {
          id
          resourceType
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerData {
            id
            detail
          }
          hasModelsOrdering
          configuratorErrorTitle
          configuratorErrorDescription
          refreshPageCtaLabel
          restartToolCtaLabel
          cancelRestartCtaLabel
          confirmRestartCtaLabel
          restartToolTitle
          restartToolDescription
          steps {
            # NGC Starts
            ... on ModelSelection {
              resourceType
              label
              selectButtonLabel
              disclaimer
              disclaimerLabel
              visualMessageNoticeAdv
              visualMessageNoticeDowngraded
              visualMessageNoticeDowngradedInActive
              visualMessageDisconnect
              visualMessageReconnect
              showConfigError
              enterCodeLabel
              enterCodePopup {
                codeEntryHeadingText
                codeEntryPlaceholder
                codeEntryErrorMessage
                continueLabel
                startNewLabel
                codeEntryTooltip
                promptHeadingText
                promptErrorMessageHeading
                promptErrorMessage
              }
              progressBar {
                resourceType
                previousCta {
                  URL
                  label
                }
              }
              topFeaturesLabel
              allFeaturesLabel
              engineLabel
              fuelTypeLabel
              accelerationLabel
              maxSpeedLabel
              fuelEfficiencyLabel
              co2emissionLabel
              addPriceDisclaimer
              priceDisclaimerId
              enableFeatureOverview
              disclaimerData {
                id
                detail
              }
            }
            ... on ConfigureSelection {
              resourceType
              label
              stepLabel
              includedText
              headingText {
                title
                text
              }
              onlyLabel
              powerTypeMessage
              saveCtaLabel
              moreInfoLabel
              learnMoreLabel
              nextCTALink
              disclaimer
              disclaimerLabel
              saveCarPopup {
                saveCarHeadingText
                saveCarStandoutText
                codeUpdatedLabel
                shareDesktopLabel
                copyLabel
                shareLabel
              }
              progressBar {
                resourceType
                previousCta {
                  URL
                  label
                }
              }
              backgroundImage {
                altText
                image
              }
              transmissionLabel
              transmissionTitle
              transmissionDescription
              learnMoreTransmission {
                key
                title
                description
              }
              drivetrainLabel
              drivetrainTitle
              drivetrainDescription
              learnMoreDrivetrain {
                key
                title
                description
              }
              fuelTypeLabel
              fuelTypeTitle
              fuelTypeDescription
              learnMoreFueltype {
                key
                title
                description
              }
              learnMoreInterior
              learnMoreInteriorDescription
              learnMoreInteriorTitle
              interiorMessage
              interiorLabel
              exteriorLabel
              addPriceDisclaimer
              priceDisclaimerId
              disclaimerData {
                id
                detail
              }
            }
            ... on AccessoriesSelection {
              resourceType
              label
              stepLabel
              headingText {
                title
                text
              }
              disclaimer
              disclaimerLabel
              accessorySelectionStandoutText
              saveCtaLabel
              moreInfoLabel
              pricingExcludeVATLabel
              pricingIncludeVATLabel
              addAccessoryCTA
              removeAccessoryCTA
              removeMessageHeadingText
              removeMessage
              progressBar {
                resourceType
                previousCta {
                  URL
                  label
                }
              }
              popularLabel
              popularChoiceLabel
              includedText
              addPriceDisclaimer
              priceDisclaimerId
              disclaimerData {
                id
                detail
              }
            }
            ... on SummarySelection {
              resourceType
              label
              stepLabel
              includedText
              backgroundImage {
                altText
                image
              }
              addPriceDisclaimer
              priceDisclaimerId
              disclaimerData {
                id
                detail
              }
              headingText {
                text
                title
              }
              description
              summaryYourSelection

              disclaimer
              disclaimerLabel

              exploreFinanceLabel
              exploreFinancePath
              exploreFinanceOpenNewTab

              accessoriesSummaryLabel
              accessoriesSummaryHeadingText
              accessoriesSummaryStandoutText
              accessoriesSummaryCtaLabel

              interiorSummaryLabel
              interiorSummaryHeadingText
              interiorStandoutText
              interiorImageHolder

              topFeaturesSummaryLabel
              topFeaturesSummaryHeadingText

              allFeatureSummaryLabel
              allFeatureSummaryHeadingText
              allFeatureStandoutText

              measurementLabel
              fullDetailsLabel

              downloadBrochureCTALabel
              downloadBrochurePath
              downloadBrochureOpenNewTab

              pricingExcludeVATLabel
              pricingIncludeVATLabel
              removeAccessoryCTA
              moreInfoLabel
              saveCtaLabel
              pricingSummaryStandoutText
              removeLabel
              priceLabel

              miniLocatorPlaceHolder
              miniLocatorDescription
              miniLocatorLocationUseText
              miniLocatorHeadingText
              miniLocatorAllDealershipsSearchFilterLabel
              miniLocatorClosedLabel
              miniLocatorSalesLabel
              miniLocatorServicesLabel
              miniLocatorBodyAndPaintLabel
              miniLocatorUsedCarsSalesLabel
              miniLocatorDealerDirection
              miniLocatorDealerMap
              miniLocatorDealerList
              miniLocatorSearchRadius
              miniLocatorShowRating
              miniLocatorSearchResultsFoundLabel
              miniLocatorNoDealershipFoundMessage
              miniLocatorSaleOpeningHoursLabel
              miniLocatorServiceOpeningHoursLabel
              miniLocatorContactLabel
              miniLocatorNoGeolocationMessage

              stepNavigationTestDriveText
              stepNavigationTestDriveLink
              stepNavigationBuyOnlineTxt
              stepNavigationBuyOnlineLink

              findDealerCTALabel
              findDealerLink

              cta1Label
              cta1Link
              cta2Label
              cta2Link

              msgAccessoriesNotShow
              msgAccessoriesNotShowBreakDown

              msrpAsBuiltLabel

              saveCarPopup {
                saveCarHeadingText
                saveCarStandoutText
                codeUpdatedLabel
                shareDesktopLabel
                copyLabel
                shareLabel
              }

              progressBar {
                resourceType
                previousCta {
                  URL
                  label
                }
              }
              paymentCalculator {
                msrpLabel
                totalMSRPBuiltLabel
                MSRP {
                  value
                  displayValue
                }
                buyForLabel
                leaseForLabel
                buyTabLabel
                leaseTabLabel
                termsLabel
                tradeInValueLabel
                downPaymentLabel
                customRateLabel
                monthSuffixLabel
                interestLabel
                defaultRateLabel
                amountFinancedLabel
                amountFinancedInclusionText
                dueLeasingLabel
                dueLeasingInclusionText
                annualMileageLabel
                requestQuoteLabel
                calculationErrorTitle
                calculationErrorText
                minimumLoanAllowance
                minimumLoanAllowanceError
                labelOffer
                errorMessageDownPaymentAndTradeIn
                regexRestrictDecimalPricing
                regexRestrictDecimalRating
                financeCalculation {
                  downPayment {
                    value
                    displayValue
                  }
                  tradeInValue {
                    value
                    displayValue
                  }
                  terms {
                    value
                    default
                  }
                  rate
                  validationRateErrorMsg
                  customerCashback {
                    value
                    displayValue
                  }
                  amountFinanced {
                    value
                    displayValue
                  }
                  monthlyAmount {
                    value
                    displayValue
                  }
                  requestQuoteRedirectUrl
                }
                leaseCalculation {
                  downPayment {
                    value
                    displayValue
                  }
                  tradeInValue {
                    value
                    displayValue
                  }
                  terms {
                    value
                    default
                  }
                  annualMileage
                  dueAtLeasing {
                    value
                    displayValue
                  }

                  monthlyAmount {
                    value
                    displayValue
                  }
                  requestQuoteRedirectUrl
                }
                addPriceDisclaimer
                priceDisclaimerId
                disclaimerData {
                  id
                  detail
                }
              }
            }
            # NGC Ends
            ... on CarSelectorStep {
              label
              headingText {
                title
                text
              }
              selectorGrid {
                componentLabel
                productTile {
                  id
                  name
                  vehicleImage {
                    image
                    altText
                  }
                  cta {
                    url
                    label
                  }
                }
              }
            }
            ... on ModelPreferencesSelector {
              label
              headingText {
                title
                text
              }
              progressBar {
                resourceType
                previousCta {
                  URL
                  label
                  theme
                  openNewTab
                }
                items {
                  label
                  selected
                }
                startConfigurationTitle
              }
            }
            ... on ModelSelector {
              label
              headingText {
                title
                text
              }
              addPriceDisclaimer
              priceDisclaimerId
              disclaimerData {
                id
                detail
              }
            }
            ... on SwatchSelector {
              label
              headingText {
                title
                text
              }
            }
            ... on SummarySelector {
              label
              headingText {
                title
                text
              }
              summary {
                msrpDisclaimerId
                monthlyMsrpDisclaimerId
                buyMonthlyDisclaimerId
                interestRateDisclaimerId
                leaseMonthlyDisclaimerId
                annualMileageDisclaimerId
                dueAtLeaseSigningDisclaimerId
              }
              addPriceDisclaimer
              priceDisclaimerId
              disclaimerData {
                id
                detail
              }
            }
          }
          changeVehicleOverlay {
            overlayCopy {
              title
              text
            }
            links {
              url
              label
              priority
              ctaType
            }
          }
        }

        ... on TablesContainer {
          id
          resourceType
          lastModified
          sectionTitle
          sectionId
          tableTabbed {
            resourceType
            keyFeatureTitle
            viewMoreLabel
            viewLessLabel
            displayShowMore
            includeFees
            addPriceDisclaimer
            priceDisclaimerId
            disclaimerData {
              id
              detail
            }
            link {
              url
              label
              ctaType
              priority
              theme
            }
            legalCopy
            specificationGroup {
              groupID
              sectionTitle
            }
            products {
              name
              code
              descriptionList
              engineSize
              fuelType
              transmissionType
              drivetrain
              price {
                value
                baseValue
                displayValue
                zeroMessage
              }
              tag {
                code
                label
              }
              image {
                image
                altText
              }
              frontImage {
                image
                altText
              }
              specifications {
                label
              }
              detailedSpecifications {
                type
                label
                titleAndText {
                  title
                }
              }
              cta {
                url
                label
                priority
                icon
                openNewTab
              }
              additionalCtas {
                url
                label
                priority
                icon
                openNewTab
              }
            }
          }
          tableMixed {
            addPriceDisclaimer
            priceDisclaimerId
            resourceType
            keyFeatureTitle
            link {
              url
              label
              ctaType
              priority
              openNewTab
              theme
            }
            legalCopy
            specificationGroup {
              groupID
              sectionTitle
            }
            disclaimerData {
              id
              detail
            }
            products {
              name
              descriptionList
              engineSize
              fuelType
              transmissionType
              drivetrain
              price {
                value
                displayValue
                zeroMessage
              }
              tag {
                code
                label
              }
              image {
                image
                altText
              }
              frontImage {
                image
                altText
              }
              specifications {
                label
              }
              detailedSpecifications {
                type
                label
                titleAndText {
                  title
                }
              }
              dimensionImages {
                image
                altText
              }
            }
          }
        }

        ... on AccessoryGrid {
          resourceType
          heading
          containerHeadline {
            URL
            label
            openNewTab
          }
          filters {
            code
            label
            filteringOptions {
              code
              label
            }
          }
          gridCard {
            lastModified
            feature
            attributes {
              code
              label
            }
            backgroundImage {
              image
              altText
            }
            textSectionBlock {
              sectionHeading
              mainHeading
              paragraph
              preTaxPrice {
                label
                value
                displayValue
              }
              totalPrice {
                label
                value
                displayValue
              }
              price {
                value
                displayValue
                zeroMessage
              }
              link {
                url
                label
                priority
                ctaType
                icon
              }
              theme
            }
          }
        }
        ... on ArticleListing {
          resourceType
          viewMoreLabel
          type
          pageSize
          articles {
            date
            articleInformation {
              id
              mainHeading
              sectionHeading
              paragraph
              link {
                url
                label
                priority
                openNewTab
              }
              image {
                image
                altText
              }
            }
          }
        }

        ... on SectionPage {
          disclaimerData {
            id
            detail
          }
          resourceType
          sections {
            label
            items {
              year
              description {
                title
                text
              }
              itemType
              alignment
              image {
                image
                altText
              }
              moreDetail {
                links {
                  url
                  label
                  priority
                  openNewTab
                }
              }
            }
          }
        }

        ... on CompareSection {
          resourceType
          componentHeadline
          nextText
          steps {
            name
            description
          }
          carSelector {
            productTile {
              id
              code
              year
              codeYear
              price {
                displayValue
                value
                zeroMessage
              }
              vehicleImage {
                image
                altText
              }
              vehicleBackgroundImage {
                image
                altText
              }
              name
              description
              selected
              cta {
                url
                label
              }
              buildLink {
                url
                label
              }
              tag {
                code
                label
              }
              selectLabel
            }
          }
        }

        ... on VehiclesCompareSection {
          resourceType
          componentHeadline
          collapseAllText
          expandAllText
          addVehicleLabel
          changeVehicleLabel
          deleteVehicleLabel
          steps {
            name
            description
            mobileViewDescription
            mobileLandscapeViewDescription
            tabletViewDescription
          }
          restartActionMainLabel
          restartActionLabel
          restartActionDescription
          restartConfirmationLabel
          restartCancelLabel
          carCompare {
            productTile {
              id
              code
              year
              codeYear
              price {
                displayValue
                value
                zeroMessage
              }
              vehicleImage {
                image
                altText
              }
              vehicleBackgroundImage {
                image
                altText
              }
              name
              description
              selected
              selectTrimLabel
              trimCountText
              cta {
                url
                label
              }
              buildLink {
                url
                label
              }
              tag {
                code
                label
              }
              selectLabel
              models {
                id
                code
                selected
                price {
                  label
                  value
                  displayValue
                  baseValue
                  zeroMessage
                  fees {
                    id
                    label
                    value
                  }
                }
                name
                detailedSpecifications {
                  type
                  code
                  label
                  price {
                    displayValue
                  }
                  titleAndText {
                    title
                    text
                  }
                  selected
                  link {
                    URL
                  }
                  availablility
                }
              }
            }
            specificationGroup {
              groupID
              sectionTitle
            }
            subSpecificationGroup {
              parentGroupID
              categoryID
              categoryTitle
            }
          }
        }

        ... on VariantComparisonTool {
          resourceType
          vehicleHeading
          vehicleDescription
          collapseAllText
          expandAllText
          modelName
          carCompare {
            productTile {
              id
              code
              year
              codeYear
              price {
                displayValue
                value
                zeroMessage
              }
              vehicleImage {
                image
                altText
              }
              vehicleBackgroundImage {
                image
                altText
              }
              name
              description
              selected
              selectTrimLabel
              trimCountText
              cta {
                url
                label
              }
              buildLink {
                url
                label
              }
              tag {
                code
                label
              }
              selectLabel
              models {
                id
                code
                selected
                image {
                  image
                  altText
                }
                cta {
                  url
                  label
                }
                price {
                  label
                  value
                  displayValue
                  baseValue
                  zeroMessage
                  fees {
                    id
                    label
                    value
                  }
                }
                name
                detailedSpecifications {
                  type
                  code
                  label
                  price {
                    displayValue
                  }
                  titleAndText {
                    title
                    text
                  }
                  selected
                  link {
                    URL
                  }
                  availablility
                }
              }
            }
            specificationGroup {
              groupID
              sectionTitle
            }
            subSpecificationGroup {
              parentGroupID
              categoryID
              categoryTitle
            }
          }
        }

        ... on ${FORM_QUERY_BLOCK}
        ... on VinRecallChecker {
          resourceType
          headline
          headingText {
            title
            text
          }
          activeRecallNextSteps
          activeRecallTitle
          noActiveRecallTitle
          searchBarMinCharacterLength
          searchBarMaxCharacterLength
          searchBarPlaceHolder
          searchAgainLabel
          searchLabel
          vinValidationError
          cta1 {
            URL
            label
            priority
            ctaType
            icon
          }
          cta2 {
            URL
            label
            priority
            ctaType
            icon
          }
        }

        ... on AnchorLink {
          resourceType
          anchorID
        }
      }

      footer {
        id
        categories {
          heading
          links {
            url
            label
            openNewTab
          }
          order
        }
        languages {
          selected
          acronym
          fullName
        }
        sociallinks {
          url
          label
          openNewTab
          image {
            altText
            image
          }
        }
        disclaimer {
          url
          label
          openNewTab
        }
        flagIcon {
          image
          altText
        }
        globalSiteNavigation {
          closeLabel
          changeCountryLabel
          titleText
          regions {
            regionName
            countries {
              name
              link
              language
              flag
            }
          }
        }
        copyRight
        locationSelector {
          useCurrentLocationLabel
          inputLocationLabel
          resourceType
        }
      }

      header {
        id
        displaySearchfield
        searchLabel
        searchPlaceholder
        searchInputWarningMessage
        mobileQuickLinks {
          label
          url
          openNewTab
        }
        menuModule {
          open
          closed
        }
        languages {
          code
          selected
          fullName
          acronym
          tooltip
        }
        quickLinks {
          url
          label
        }
        logo {
          altText
          whiteImage {
            image
          }
          blackImage {
            image
          }
          link {
            url
          }
        }
        sectionLinks {
          url
          label
          selected
        }
        kbaLinks {
          url
          label
        }
        navigationType
        flyoutMenu {
          name
          noDropDown
          subNavigationcontainer {
            promoterDetails {
              header
              ctalabel
              ctaLink
              description
              openNewTab
              image {
                image
                altText
              }
            }
            subNavigationItems {
              title
              links {
                url
                label
                order
                openNewTab
              }
            } 
          }
          products {
            id
            code
            description
            name
            productSkuId
            enableProductsRatingStars
            disclaimerIdVehicleCard
            price {
              label
              value
              displayValue
              zeroMessage
            }
            vehicleImage {
              altText
              image
            }
            vehicleBackgroundImage {
              altText
              image
            }
            specifications {
              id
            }
            cta {
              description
              order
              vehicleTag
              placement
              parentItem
              openNewTab
              url
              label
            }
            tag {
              label
            }
          }
          links {
            url
            label
            order
            openNewTab
          }
          backgroundImage {
            altText
            image
          }
          addPriceDisclaimer
          priceDisclaimerId
          disclaimerDataForNav {
            id
            detail
          }
          displayNavInTwoColumns
        }
      }

      cookieConsent {
        # mainHeading
        paragraph
        legal
        link {
          label
          priority
        }
      }
    }
  }
`;

export default PageQuery;
