export const SWITCH_QUERY_BLOCK = `
... on TextSectionBlock {
  id
  disclaimerData {
    id
    detail
  }
  addPriceDisclaimer
  priceDisclaimerId
  resourceType
  lastModified
  sectionTitle
  sectionId
  headingTag
  width
  image {
    altText
    image
  }
  link {
    url
    label
    priority
    openNewTab
    theme
    icon
  }
  mainHeading
  textAlignment
  sectionHeading
  disclaimerLabel
  useAccordion
  hideDisclaimer
  paragraph
  theme
  logo {
    altText
    image
  }
  offerTag {
    code
    label
  }
}

... on BrandPanel {
  disclaimerData {
    id
    detail
  }
  id
  resourceType
  lastModified
  sectionTitle
  sectionId
  panel {
    image {
      image
      altText
    }
    heading1
    heading2
    headingLabel
    textBlock
    link {
      URL
      label
      ctaType
      theme
      priority
      icon
      openNewTab
    }
  }
}

... on KeyBuyingAction {
  id
  resourceType
  lastModified
  hideActionBar
  links {
    openNewTab
    url
    label
    icon
    image {
      image
    }
  }
  image {
    image
  }
}

... on CarSelector {
  addPriceDisclaimer
  priceDisclaimerId
  disclaimerData {
    id
    detail
  }
  id
  resourceType
  lastModified
  selectedLabel
  componentLabel
  selectIcon {
    altText
    image
  }
  showSelection
  enableTwoColumns
  productTile {
    enableProductsRatingStars
    productSkuId
    parentProductSkuId
    code
    codeYear
    year
    price {
      value
      displayValue
      zeroMessage
    }
    vehicleImage {
      image
      altText
    }
    vehicleBackgroundImage {
      image
      altText
    }
    name
    cta {
      url
      label
    }
    buildLink {
      url
      label
    }
    tag {
      code
      label
    }
    vehicleNextGenConfigurator
  }
  customizedLabel
}

... on TextQuote {
  id
  resourceType
  lastModified
  quoteText
  quoteTextSmall
  caption
  alignment
  link {
    url
    label
  }
  image {
    image
    altText
  }
  theme
}

... on DealerLocator {
  id
  resourceType
  lastModified
  title
  subHeadline
  searchHelpText
  locationCtaLabel
  serviceSearchFilterLabel
  salesSearchFilterLabel
  allDealershipsSearchFilterLabel
  getDirectionsLabel
  headingTag
  testDriveCTA {
    URL
    label
    openNewTab
  }
  carServiceCTA {
    URL
    label
    openNewTab
  }
  clickShopCTA {
    URL
    label
    openNewTab
  }
  scheduleServiceLabel
  clickshopParamters
  contactUsLabel
  cta {
    url
    label
    priority
    icon
    openNewTab
  }
  searchInventoryLabel
  searchRadius
  showRating
  searchResultsFoundLabel
  saleOpeningHoursLabel
  serviceOpeningHoursLabel
  contactLabel
  metricSystem
  viewMoreText
  noGeolocationMessage
  noDealershipFoundMessage
  salesLabel
  servicesLabel
  bodyAndPaintLabel
  usedCarsSalesLabel
  listLabel
  mapLabel
  additionalFilters {
    label
    value
  }
  zoomLevel
}

... on ProductLineUp {
  id
  resourceType
  lastModified
  buildText
  vehicles {
    id
    code
    price {
      label
      value
      zeroMessage
    }
    vehicleImage {
      altText
      image
    }
    vehicleBackgroundImage {
      altText
      image
    }
    name
    description
  }
}

... on FilterSection {
  id
  resourceType
  lastModified
  filters {
    label
    filteringOptions {
      label
      image {
        altText
        image
      }
      imageSelected {
        altText
        image
      }
      price {
        label
        value
        displayValue
        zeroMessage
      }
      type
      id
      titleAndText {
        title
        text
      }
      selected
    }
  }
}

... on Carousel {
  id
  resourceType
  lastModified
  headingTag
  introText
  titleAndText {
    title
    text
  }
  staticImage {
    altText
    image
  }
  items {
    titleAndText {
      title
      text
    }
    image {
      altText
      image
    }
    backgroundImage {
      altText
      image
    }
    content {
      text
      title
    }
    valueSection {
      label
      value
      valueSize
      icon
    }
    cta {
      label
      url
      priority
      openNewTab
      icon
    }
  }
  disclaimerData {
    id
    detail
  }
}

... on ModelSelector {
  id
  resourceType
  lastModified
  label
  compareLink {
    URL
    label
    openNewTab
  }
  titleAndText {
    title
    text
  }
  models {
    id
    code
    price {
      label
      value
      displayValue
      zeroMessage
    }
    name
    description
    selected
    exteriorColors {
      id
      code
      name
      image {
        image
        altText
      }
      price {
        label
        value
        displayValue
        baseValue
        fees {
          label
          value
          id
        }
        zeroMessage
      }
    }
    specifications {
      id
      label
      titleAndText {
        title
        text
      }
    }
  }
  addPriceDisclaimer
  priceDisclaimerId
  disclaimerData {
    id
    detail
  }
}

... on SelectionSummary {
  id
  resourceType
  lastModified
  summaryText
  changeText
  includedText
  editText
  summaryTitle
  configurationTitle
  customisationTitle
  copyText
  hideLink
  nextButton
  summaryImage
  includedAccessoriesTitle
  vehicle {
    id
    code
    price {
      label
      value
      displayValue
      zeroMessage
    }
    name
    selected
  }
  model {
    id
    code
    price {
      label
      value
      displayValue
      zeroMessage
    }
    name
    selected
  }
  color {
    id
    type
    code
    label
    price {
      label
      value
      displayValue
    }
    titleAndText {
      title
      text
    }
    selected
  }
  fabric {
    id
    type
    code
    label
    price {
      label
      value
      displayValue
    }
    titleAndText {
      title
      text
    }
    selected
  }
  packages {
    id
    type
    code
    label
    price {
      label
      value
      displayValue
    }
    titleAndText {
      title
      text
    }
    selected
  }
  accessories {
    type
    code
    label
    colorsLabel
    colorDescription
    dimensions
    dimensionsLabel
    compatibleName
    compatibleLabel
    price {
      label
      value
      displayValue
    }
    titleAndText {
      title
      text
    }
    selected
  }
  totalPrice {
    label
    value
    displayValue
  }
  fees {
    label
    value
  }
  preTaxPrice {
    value
    label
    displayValue
  }
  driveType {
    id
    type
    code
    label
    image {
      altText
      image
    }
    selected
  }
  fuelType {
    id
    type
    code
    label
    selected
  }
  transmissionType {
    id
    type
    code
    label
    image {
      altText
      image
    }
    selected
  }
}

... on PackagesSelector {
  id
  resourceType
  lastModified
  label
  filter {
    code
    label
    filteringOptions {
      id
      type
      code
      label
      image {
        altText
        image
      }
      price {
        label
        value
        displayValue
      }
      includedOptions {
        id
        type
        label
        titleAndText {
          title
          text
        }
        image {
          image
          altText
        }
        link {
          URL
          label
          openNewTab
        }
      }
      titleAndText {
        title
        text
      }
      selected
    }
  }
  titleAndText {
    title
    text
  }
}

... on SwatchSelector {
  id
  resourceType
  lastModified
  label
  titleAndText {
    title
    text
  }
  filter {
    code
    label
    filteringOptions {
      id
      type
      code
      label
      image {
        altText
        image
      }
      price {
        label
        value
        displayValue
      }
      titleAndText {
        title
        text
      }
      selected
    }
  }
}

... on CtaRow {
  id
  resourceType
  lastModified
  headline
  alignment
  description
  label
  textSpan
  CTAs {
    label
    url
    priority
    theme
    ctaType
    icon
    openNewTab
  }
}

... on CtaRowV2 {
  resourceType
  headline
  ctas {
    label
    link
    linkOriginal
    selectActionType
    openNewTab
  }
}

... on LocatorMap {
  id
  resourceType
  lastModified
  image {
    image
    altText
  }
  hoursTitle
  contactTitle
  contactEmailTitle
  contactChatTitle
  contactPhone1
  contactPhone2
  location {
    name
    address {
      country
      city
      postalArea
      addressLine1
      addressLine2
      addressLine3
    }
    dealerDepartments {
      workingHours {
        dayOfTheWeek
        openTime
        closeTime
        status
      }
    }
  }
}

... on Contact {
  id
  resourceType
  lastModified
  columns {
    heading
    contacts {
      heading
      contact {
        url
        label
        priority
        icon
      }
    }
  }
}

... on IFrame {
  id
  resourceType
  lastModified
  src
  title
  allowGeolocation
  addGTM
  iframeHeight
  iframeMobileHeight
  iframeMobileWidth
  iframeTabletHeight
  iframeTabletWidth
  iframeDesktopHeight
  iframeDesktopWidth
  iframeSize
  position
  theme
  defaultSetting
  enableJato
  mobileIframePath
  jatoDisclaimerText
  jatoLogoImage {
    image
    altText
  }
}

... on TablesContainer {
  id
  resourceType
  lastModified
  sectionTitle
  sectionId
  tableTabbed {
    resourceType
    keyFeatureTitle
    viewMoreLabel
    viewLessLabel
    displayShowMore
    includeFees
    addPriceDisclaimer
    priceDisclaimerId
    disclaimerData {
      id
      detail
    }
    link {
      url
      label
      ctaType
      priority
      theme
    }
    legalCopy
    specificationGroup {
      groupID
      sectionTitle
    }
    products {
      name
      code
      descriptionList
      engineSize
      fuelType
      transmissionType
      drivetrain
      price {
        value
        baseValue
        displayValue
        zeroMessage
      }
      tag {
        code
        label
      }
      image {
        image
        altText
      }
      frontImage {
        image
        altText
      }
      specifications {
        label
      }
      detailedSpecifications {
        type
        label
        titleAndText {
          title
        }
      }
      cta {
        url
        label
        priority
        icon
        openNewTab
      }
      additionalCtas {
        url
        label
        priority
        icon
        openNewTab
      }
    }
  }
  tableMixed {
    addPriceDisclaimer
    priceDisclaimerId
    resourceType
    keyFeatureTitle
    link {
      url
      label
      ctaType
      priority
      openNewTab
      theme
    }
    legalCopy
    specificationGroup {
      groupID
      sectionTitle
    }
    disclaimerData {
      id
      detail
    }
    products {
      name
      descriptionList
      engineSize
      fuelType
      transmissionType
      drivetrain
      price {
        value
        displayValue
        zeroMessage
      }
      tag {
        code
        label
      }
      image {
        image
        altText
      }
      frontImage {
        image
        altText
      }
      specifications {
        label
      }
      detailedSpecifications {
        type
        label
        titleAndText {
          title
        }
      }
      dimensionImages {
        image
        altText
      }
    }
  }
}

... on SectionPage {
  disclaimerData {
    id
    detail
  }
  resourceType
  sections {
    label
    items {
      year
      description {
        title
        text
      }
      itemType
      alignment
      image {
        image
        altText
      }
      moreDetail {
        links {
          url
          label
          priority
          openNewTab
        }
      }
    }
  }
}

... on ArticleListing {
  resourceType
  viewMoreLabel
  type
  pageSize
  articles {
    date
    articleInformation {
      id
      mainHeading
      sectionHeading
      paragraph
      link {
        url
        label
        priority
        openNewTab
      }
      image {
        image
        altText
      }
    }
  }
}

... on AccessoryGrid {
  resourceType
  heading
  containerHeadline {
    URL
    label
    openNewTab
  }
  filters {
    code
    label
    filteringOptions {
      code
      label
    }
  }
  gridCard {
    lastModified
    feature
    attributes {
      code
      label
    }
    backgroundImage {
      image
      altText
    }
    textSectionBlock {
      sectionHeading
      mainHeading
      paragraph
      preTaxPrice {
        label
        value
        displayValue
      }
      totalPrice {
        label
        value
        displayValue
      }
      price {
        value
        displayValue
        zeroMessage
      }
      link {
        url
        label
        priority
        ctaType
        icon
      }
      theme
    }
  }
}

... on ProfileGrid {
  resourceType
  contactCards {
    image {
      image
      altText
    }
    userInformation {
      sectionHeading
      mainHeading
      paragraph
    }
    contactCTAs {
      icon
      label
    }
  }
}

... on CompareSection {
  resourceType
  componentHeadline
  nextText
  steps {
    name
    description
  }
  carSelector {
    productTile {
      id
      code
      year
      codeYear
      price {
        displayValue
        value
        zeroMessage
      }
      vehicleImage {
        image
        altText
      }
      vehicleBackgroundImage {
        image
        altText
      }
      name
      description
      selected
      cta {
        url
        label
      }
      buildLink {
        url
        label
      }
      tag {
        code
        label
      }
      selectLabel
    }
  }
}

... on TableStep {
  resourceType
  categories {
    heading
    order
    textSection {
      lastModified
      paragraph
      link {
        url
        label
        priority
        openNewTab
        icon
      }
      anchorPoint
    }
  }
  disclaimerData {
    id
    detail
  }
}

... on Specifications {
  resourceType
  title
  label
  vehicle {
    name
    vehicleImage {
      image
      altText
    }
  }
  hideComparationTabs
  dimensionImages {
    image
    altText
  }
  showImageWithDimentions
  showFeaturesWithAlignment
  buttonAlignment
  hasModelsOrdering
  models {
    id
    code
    name
    sortOrder
    price {
      value
      label
      displayValue
      zeroMessage
    }
    specifications {
      category
      label
      value
      unitOfMeasurement
      sortOrder
    }
    features {
      parentCategory
      category
      description
      sortOrder
      isKeyFeature
    }
  }
  addPriceDisclaimer
  priceDisclaimerId
  disclaimerData {
    id
    detail
  }
}

... on FinanceData {
  resourceType
  viewMoreLabel
  viewLessLabel
  financeColumns {
    textSectionBlock {
      sectionHeading
      mainHeading
      paragraph
      legal
    }
    link {
      url
      label
      priority
      ctaType
      icon
    }
    infographic {
      image {
        image
        altText
      }
      leftLabel
      centerLabel
      rightLabel
      description
    }
  }
  moreInfo {
    description
    columns {
      textSectionBlock {
        sectionHeading
        mainHeading
        paragraph
        legal
      }
      textAlignment
      link {
        label
        url
      }
      list
      infographic {
        image {
          image
          altText
        }
        leftLabel
        centerLabel
        rightLabel
        description
      }
    }
  }
}

... on HTMLEditor {
  resourceType
  html
}

... on Offer {
  locale
  resourceType
  offerLabel {
    viewOfferCTA
    viewHomePage
    seeAllVehicleOffers
    viewHomePagePath
    filterOfferHeadingText
    seeOffersLabel
    seeOffersForVehiclesLabel
    seeOffersNearZipCodeLabel
    seeOffersForVehiclesNearZipCodeLabel
  }
  offerFilter {
    offerFilterSelectionMobileLabel
    allOfferTypeSelectionLabel
    allModelYearLabel
    offerTypeFilterLabel
    allVehiclesLabel
    selectVehiclesLabel
    offerNotAvailable
    filterOfferHeadingText
    zipCodeErrorMsg
    offerNotAvailableTitle
    clearButtonLabel
    errorTitle
    errorContent
    timeOutMsgTitle
    timeOutMsgContent
    zipCodeLabel
    modelYearLabel
    applyButtonLabel
    cancelButtonLabel
    zipCode {
      validationRegEx
    }
    vehicles {
      vehicleName
      imagePath
      code
      noOfOffers
      imageAltText
    }
    offerTypes {
      label
      id
    }
    modelYear {
      year
      id
    }
    offersLabel
  }
  offerList {
    vehicleName
    vehicleCode
    image
    parentProductSkuId
    isEnableProductStar
    altText
    legalTextForImageVehicle
    legalTextForImageDisclaimerData {
      id
      detail
    }
    offers {
      offerCode
      modelName
      trimCode
      trimName
      offerCode
      offerType
      offerTypeDisplayName
      offerDisclaimerLabel
      detailPageUrl
      cardEyebrowText
      legalTextImage
      subHeadlineTextTwo
      descriptions {
        type
        text
      }
      disclaimerData {
        id
        detail
      }
    }
  }
}

... on OfferDetail {
  resourceType
  addPriceDisclaimer
  allInPriceLabel
  priceDisclaimerId
  offerSubHeadlineText2
  disclaimerLabel
  offerDetail {
    allInPriceValue
    legalTextImage
    modelCode
    modelName
    modelYear
    trimCode
    trimName
    transmissionCode
    vehicleCode
    vehicleName
    displayName
    offerCode
    offerType
    offerValid
    offerDisclaimerLabel
    imagePath
    imageAltText
    shareOfferCode
    descriptions {
      type
      text
    }
    CTAs {
      label
      url
      priority
      theme
      ctaType
      icon
      openNewTab
    }      
    disclaimerData {
      id
      detail
    }
  }

  inventoryLabel
  inventoryDisplay
  inventoryRedirectionURL

  buildAndPriceLabel
  buildAndPriceDisplay
  buildAndPriceRedirectionUrl

  shareOrPrintOfferLabel

  tradeInAppraisalOfferLabel

  shareOfferLabel
  shareOfferDisplay

  printOfferLabel
  printOfferDisplay

  shareOfferDialogLabel
  copiedLabel
  copyLabel
  shareIconText

  availabilityMsg

  clickShopEnabled
  raqCta {
    label
    link
  }

  backButtonLabel
}

... on OfferModule {
  resourceType
  offerModule {
    modelCode
    modelName
    trimCode
    trimName
    transmissionCode
    vehicleCode
    vehicleName
    offerCode
    displayName
    offerType
    modelYear
    redirectUrl
    descriptions {
      type
      text
    }
  }
  viewDetailsLabel
  seeAllOffersLabel
  seeAllOffersRedirect
}

... on MultiVehiclesSelector {
  resourceType
  locale
  zipCodeEnabled
  zipCodeHeadingText
  zipCodeLabel
  zipCodeErrorMsg
  zipCode {
    validationRegEx
  }
  msrpPrefix
  msrpDisclaimerNo
  modelYearLabel
  modelYear {
    year
    id
  }
  modelNotAvailableMsg
  vehicleCheckboxEnabled
  vehicles {
    vehicleName
    vehicleCode
    vehicleYear
    imagePath
    imageAltText
    MSRP
    isActive
    vehicleNextGenConfigurator
    configuratorURL
    price {
      value
      displayValue
      zeroMessage
    }
  }
  ctaEnabled
  ctaLabel
  ctaRedirectLink
  paymentCalculatorRedirectLink
  collapseStatus
  disclaimerLabel
  disclaimerList {
    disclaimerNumber
    disclaimerContent
    associated
  }
  customizedLabel
  ogcConfiguratorPath
  ngcConfgiuratorPath
  configuratorCodeEntryHeadingText
  configuratorCodeEntryTooltip
  configuratorRetrieveHeadingText
  configuratorCodeEntryLabel
  configuratorCodeEntryPlaceholder
  configuratorCodeEntryError
  configuratorCtas
  configuratorCodeEntryContinue
  configuratorCodeEntryStartNew
  configuratorErrorMessageHeading
  configuratorErrorMessage
  addPriceDisclaimer
  priceDisclaimerId
  disclaimerData {
    id
    detail
  }
}

... on ModelCalculation {
  disclaimerData {
    id
    detail
  }
  addPriceDisclaimer
  priceDisclaimerId
  resourceType
  subHeadingText
  paymentHeadingLabel
  paymentTermLabel
  buyCalculationLabel
  buyDescriptionLabel
  leaseCalculationLabel
  leaseDescriptionLabel
  inventoryLabel
  collapseStatus
  disclaimerLabel
  disclaimerList {
    disclaimerNumber
    disclaimerContent
    associated
  }
  addPriceDisclaimer
  priceDisclaimerId
  disclaimerData {
    id
    detail
  }
}

... on XLQuoteCarousel {
  id
  disclaimerData {
    id
    detail
  }
  resourceType
  sectionHeading
  theme
  lastModified
  cyclical
  autoplay
  autoplaySpeed
  slides {
    id
    resourceType
    lastModified
    quoteText
    quoteTextSmall
    caption
    alignment
    link {
      url
      label
      priority
      icon
      openNewTab
    }
    image {
      image
      altText
    }
    theme
    disclaimerData {
      id
      detail
    }
  }
}

... on FaqCardsPanel {
  resourceType
  introHeader
  introText
  answerLabel
  backLabel
  cards {
    question
    answer
    layout
    image {
      image
      altText
    }
  }
  CTAs {
    label
    priority
    url
    theme
    ctaType
    icon
    openNewTab
  }
  disclaimerData {
    id
    detail
  }
}

... on MiniTimeline {
  resourceType
  headerText
  introText
  miniTimelineBlocks {
    headingText
    subHeadline
    description
    disclaimerData {
      id
      detail
    }
  }
  disclaimerData {
    id
    detail
  }
}

... on TabGallery {
  resourceType
  eyebrow
  header
  introText
  disclaimerData {
    id
    detail
  }
  items {
    youtubeLink
    internalVideo
    image {
      image
      altText
    }
    description
    label
    cta {
      url
      label
      priority
      icon
      openNewTab
    }
  }
}

... on ChargingStation {
  resourceType
  freeChargingKeyLabel
  mobileListTabLabel
  chargeTypeLabel
  resultSummary
  networkLabel
  searchFieldPlaceholder
  getMyLocationLabel
  mobileMapTabLabel
  paidChargingKeyLabel
  paidChargingKeyColour
  filterHeader
  costLabel
  titleText
  subTitleText
  description
  getDirectionLabel
  freeChargingKeyColour
  radius
  radiusDistanceFromZipCodeType
  rateFilter
  plugTypeFilters
  chargeTypesFilters {
    description
    label
  }
  networksFilters {
    description
    label
  }
  costsFilters {
    description
    label
  }
  disclaimerData {
    id
    detail
  }
}

... on AnchorLink {
  resourceType
  anchorID
}


... on FeefoReviews {
  resourceType
  productSkuId
  id
}

... on FeefoScoreBadge {
  resourceType
  vehicleSkuId
  eyebrowText
  introText
  vehicleSelection
  headerText
  theme
  url
  disclaimerData {
    id
    detail
  }
}

... on FeefoHighlightedSnippets {
  resourceType
  vehicleSkuId
}

... on LocationSelector {
  resourceType
  title
  description
  useCurrentLocationLabel
  inputLocationLabel
}

... on VehiclesCompareSection {
  resourceType
  componentHeadline
  collapseAllText
  expandAllText
  addVehicleLabel
  changeVehicleLabel
  deleteVehicleLabel
  steps {
    name
    description
    mobileViewDescription
    mobileLandscapeViewDescription
    tabletViewDescription
  }
  restartActionMainLabel
  restartActionLabel
  restartActionDescription
  restartConfirmationLabel
  restartCancelLabel
  carCompare {
    productTile {
      id
      code
      year
      codeYear
      price {
        displayValue
        value
        zeroMessage
      }
      vehicleImage {
        image
        altText
      }
      vehicleBackgroundImage {
        image
        altText
      }
      name
      description
      selected
      selectTrimLabel
      trimCountText
      cta {
        url
        label
      }
      buildLink {
        url
        label
      }
      tag {
        code
        label
      }
      selectLabel
      models {
        id
        code
        selected
        price {
          label
          value
          displayValue
          baseValue
          zeroMessage
          fees {
            id
            label
            value
          }
        }
        name
        detailedSpecifications {
          type
          code
          label
          price {
            displayValue
          }
          titleAndText {
            title
            text
          }
          selected
          link {
            URL
          }
          availablility
        }
      }
    }
    specificationGroup {
      groupID
      sectionTitle
    }
    subSpecificationGroup {
      parentGroupID
      categoryID
      categoryTitle
    }
  }
}

... on VariantComparisonTool {
  resourceType
  vehicleHeading
  vehicleDescription
  collapseAllText
  expandAllText
  modelName
  carCompare {
    productTile {
      id
      code
      year
      codeYear
      price {
        displayValue
        value
        zeroMessage
      }
      vehicleImage {
        image
        altText
      }
      vehicleBackgroundImage {
        image
        altText
      }
      name
      description
      selected
      selectTrimLabel
      trimCountText
      cta {
        url
        label
      }
      buildLink {
        url
        label
      }
      tag {
        code
        label
      }
      selectLabel
      models {
        id
        code
        selected
        image {
          image
          altText
        }
        cta {
          url
          label
        }
        price {
          label
          value
          displayValue
          baseValue
          zeroMessage
          fees {
            id
            label
            value
          }
        }
        name
        detailedSpecifications {
          type
          code
          label
          price {
            displayValue
          }
          titleAndText {
            title
            text
          }
          selected
          link {
            URL
          }
          availablility
        }
      }
    }
    specificationGroup {
      groupID
      sectionTitle
    }
    subSpecificationGroup {
      parentGroupID
      categoryID
      categoryTitle
    }
  }
}

... on NewsCards {
  id
  resourceType
  title
  cardVariant
  backgroundColor
  enableMobileCarousel
  removeTopMargin
  removeBottomMargin
  cta {
    label
    url
    openNewTab
  }
  cardItems {
    colorStyle
    imageAlignment
    hideArticleDescription
    articleInformation {
      id
      date
      sectionHeading
      paragraph
      image {
        image
        altText
      }
      link {
        url
      }
      categoryName
      readTime
    }
  }
}

`